body {
  background-color: hsl(218, 23%, 16%);
}

.text-neon-green {
  color: hsl(150, 100%, 66%);
}

.bg-neon-green {
  background-color: hsl(150, 100%, 66%);
}

.text-dark-grayish-blue {
  color: hsl(217, 19%, 24%);
}

.bg-dark-grayish-blue {
  background-color: hsl(217, 19%, 24%);
}

.text-grayish-blue {
  color: hsl(217, 19%, 38%);
}

.bg-grayish-blue {
  background-color: hsl(217, 19%, 38%);
}

.bg-dark-blue {
  background-color: hsl(218, 23%, 16%);
}

.text-light-cyan {
  color: hsl(193, 38%, 86%);
}

.bg-light-cyan {
  background-color: hsl(193, 38%, 86%);
}

.advice-quote {
  font-size: 28px;
}

.pattern-divider {
  object-fit: cover;
  width: 444px;
  height: 16px;
}

.random-btn {
  box-shadow: 0 0 4px #52ffa8;
  margin-bottom: -30px;
}

.random-btn:hover {
  box-shadow: 0 0 20px #52ffa8;
  cursor: pointer;
}

.advice-section {
  display: grid;

  /* Set horizontal alignment of items in, case they have a diffrent width. */
  /* justify-items: start | end | center | stretch (default);*/
  justify-items: center;

  /* Set vertical alignment of items, in case they have a diffrent height. */
  /* align-items: start | end | center | stretch (default); */
  align-items: end;
}

.advice-section > * {
  grid-column-start: 1;
  grid-row-start: 1;
}
